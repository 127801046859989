<li class="partner-item" >
    <div class="partner-item-group">
        <div class="partner-avatar">
            <img src="{{result?.avatar}}" class="avatar" />
        </div>
    </div>
    <div class="partner-item-group">
        <div class="name">
            {{result?.name || 'Shoutly User'}}
        </div>
        <div class="workfields" *ngIf="result?.workfields">
            <div class="workfield">{{ result?.workfields[0] }}</div>
            <div class="workfield" *ngIf="result?.workfields.length > 1">{{ result?.workfields.length -1 }} <span translate>more</span></div>
        </div>
    </div>
    <div class="partner-item-group">
        <span class="mat-caption" class="email secondary-text">{{result?.email}}</span>
        <!--div class="mat-caption" class="country">{{result?.country || '-No country-'}}</div-->
    </div>
  </li>