import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { interval, filter, map } from 'rxjs'
import { ShoutlyEidSeBankIdProviderInfo, ShoutlyEidTransactionResponse } from '../../auth/models/auth.model'

export interface BankIDDialogData {
  eidTransaction?: ShoutlyEidTransactionResponse
}

@Component({
  selector: 'shared-bankid-dialog',
  templateUrl: './bankid-dialog.component.html',
  styleUrls: ['./bankid-dialog.component.scss']
})
export class BankidDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BankIDDialogData
  ) { }

  public get bankid_url(): string {
    return this.getQrString(this.data?.eidTransaction?.providerInfo?.seBankID)
  }

  public random: string

  qrString$ = interval(2000)
    .pipe(
      filter(() => !!this.data.eidTransaction?.providerInfo?.seBankID.autoStartToken),
      map(() => {
        const res = `bankid:///?autostarttoken=${this.data.eidTransaction.providerInfo?.seBankID?.autoStartToken}`
        if (this.data.eidTransaction.providerInfo?.seBankID?.processStatusInfo !== 'userSign') {
          return res + this.generateRandom()
        }
        return res
      })
    )

  getQrString(bankId: ShoutlyEidSeBankIdProviderInfo): string {
    const autoStartToken = bankId?.autoStartToken

    // qr must stop "moving" if userSign
    if (bankId?.processStatusInfo !== 'userSign') {
      this.random = this.generateRandom()
    }

    if (!autoStartToken) {
      console.log('Missing autoStartToken.')
      return null
    }

    const qrString = `bankid:///?autostarttoken=${autoStartToken}`
    // console.log(`QR string: ${qrString}`)
    return qrString
  }

  /** making different urls makes different bankid qr, simulating "animated qr" in next version (5.1) */
  generateRandom(): string {
    const rand = new Array(35).join().replace(/(.|$)/g, function () { return ((Math.random() * 36) | 0).toString(36)[Math.random() < 0.5 ? 'toString' : 'toUpperCase']() })
    return '&redirect=null#' + rand
  }

  public openBankIdApp() {
    let res = `${this.bankid_url}&redirect=null`
    let newWindow = window.open(res, '_blank')

    if (newWindow) {
      setTimeout(() => {
        newWindow.close()
      }, 1000) // Adjust the timeout as needed
    }
  }

}
