<div class="main-title">
    <mat-icon svgIcon="auth_bank_id"></mat-icon>
    <h3 translate>{{ (sign_type | translate) | titlecase }} {{'with BANKID' | translate}}</h3>
</div>

<shared-infobox *ngIf="showError as error"
    [text]="error"
    status="negative">
</shared-infobox>

<ng-container *ngIf="!(transaction$ | async) && isLoading; then loading"></ng-container>

<div class="bankid-transaction-wrapper" *ngIf="(transaction$ | async) as eidTransaction">

    <div *ngIf="!eidTransaction?.providerInfo; then loading"></div>

    <div *ngIf="!auto_start && !isStarted">
        <button
        style="width: 100%;"
        mat-flat-button color="primary"
        matSuffix
            (click)="startProcess()">
            <span>{{sign_type | translate}} </span>
            <mat-icon svgIcon="auth_bank_id"></mat-icon>
        </button>
    </div>

    <div class="provider-info">

        <ng-container *ngIf="eidTransaction?.providerInfo?.seBankID && eidTransaction?.status !== 'new' ">
            <ng-container *ngIf="eidTransaction.providerInfo?.seBankID as bankId">
                <ng-container *ngIf="!isSameDevice">
                    <div *ngIf="bankId?.processStatus !== 'failed' else tryagain">
                        <div *ngIf="bankid_url else loading">
                            <h4 translate>Scan this QR code with your phone</h4>
                            <div class="qr-wrapper">
                                <div *ngIf="bankId?.processStatusInfo && bankId?.processStatusInfo !== 'outstandingTransaction'"
                                    class="qr-overlay">
                                    <span>{{ 'eid_process_status_' + bankId?.processStatusInfo | translate }}</span>
                                </div>
                                <qr-code class="qr-code" (click)="resume()" value="{{ bankid_url + random }}" elementType="img"
                                    size="300" errorCorrectionLevel="M"></qr-code>
                            </div>
                            <br>
                            <div>
                                <span>Or click here for BankID login using</span>&nbsp;<a class="clickable"
                                    (click)="useSameDevice()" translate>the same device</a>
                            </div>
                        </div>
                    </div>
                </ng-container>
        
                <ng-container *ngIf="isSameDevice">
                    <mat-card appearance="outlined" *ngIf="bankid_url else loading" class="background-grey">
                        <button *ngIf="bankId?.processStatus !== 'failed' else tryagain"
                            [disabled]="eidTransaction.status !== 'started'" mat-flat-button color="primary" matSuffix
                            (click)="openBankIdApp()">
                            <span>{{!bankid_url ? ('loading' | translate | titlecase) + '...' : ('Launch BankID app' |
                                translate)}} </span>
                            <mat-icon>launch</mat-icon>
                        </button>
                    </mat-card>
                    <br>
                    <p><span translate>Or click here for BankID login using</span>&nbsp;<a class="clickable"
                            (click)="useOtherDevice()" translate>other device</a></p>
                </ng-container>
            </ng-container>
           
    
        </ng-container>
    </div>
</div>

<ng-template #loading>
    <div class="bankid-loader">
        <p translate>Requesting...</p>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</ng-template>

<ng-template #error>
    <shared-infobox [text]="'Something went wrong' | translate" status="negative"></shared-infobox>
</ng-template>

<ng-template #tryagain>
    <div *ngIf="true then error"></div>
    <br>
    <button type="submit" mat-stroked-button>
        <mat-icon (click)="retry()">autorenew</mat-icon>
        {{'try again' | translate | ucfirst }}
    </button>
   
</ng-template>

<div class="auth-notice">
    <div class="secondary-text">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
</div>