<div class="avatar-settings">
    <div class="profile-avatar">
        <div class="avatar" *ngIf="img" style="background-image: url('{{ img }}')"></div>
    </div>
    <div class="buttons">
        <div style="display: flex; gap: 5px;">
            <button class="add-avatar-button" mat-flat-button [disabled]="load?.state && load?.state != 'DONE'"   color="primary" (click)="fileInput.click()">{{'New photo' | translate}}</button>
            <button [disabled]="!hasImage" class="remove-avatar-button" mat-stroked-button color="primary" aria-label="remove avatar" (click)="removeAvatar()">
                <mat-icon>delete</mat-icon>
            </button>
        </div> 
        <input type="file" (change)="onFileInput(fileInput.files)" #fileInput name="avatar" [hidden]="true" accept="image/*"/>
        <span class="message secondary-text" translate>png, jpg or pdf. Max size 5MB</span>
        <div *ngIf="load">
            <mat-progress-bar
            *ngIf="load.state != 'DONE'"
            [mode]="load.state == 'PENDING' ? 'buffer' : 'determinate'"
            [value]="load.progress"
          >
          </mat-progress-bar>
        </div>
    </div>
</div>