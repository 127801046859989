import { Component } from '@angular/core';

@Component({
  selector: 'shared-table-header',
  templateUrl: './table-header.component.html',
  styleUrl: './table-header.component.scss'
})
export class TableHeaderComponent {
  
}
